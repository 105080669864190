// ---- MAIN CONFIGURATION ---- //
// Please set the environment:
const environment = "dev"; // dev, prod, or local

// Please set your cognito domain:
const CognitoDomain = "manulife-wallboard.auth.us-east-2.amazoncognito.com";

// Please set your Cognito user pool ID:
const userPoolId = "us-east-2_uhhv7GvIC";

let redirectUrl = () => {
  // Sets redirect urls depending on environment
  switch (environment) {
    case "dev":
      return "https://d31lq3qgvab8wz.cloudfront.net/";
    case "prod":
      return "https://manulife-wallboard.tcsi2i.com";
    default:
      return "http://localhost:3000/";
  }
};

let api_endpoint = () => {
  switch (environment) {
    case "prod":
      return "https://vxj5ntfmal.execute-api.us-east-2.amazonaws.com/";
    case "dev":
    default:
      return "https://fe5glf082a.execute-api.us-east-2.amazonaws.com/";
  }
};

let stage = () => {
  switch (environment) {
    case "prod":
      return "PROD/";

    case "dev":
    default:
      return "DEV/";
  }
};

const api_url = api_endpoint() + stage();

let userPoolWebClientId = () => {
  // Sets redirect urls depending on environment
  switch (environment) {
    case "prod":
      // Only for prod
      return "7f9vh06oc9sduamir90hvkn8e";
    default:
      // Dev and local
      return "13uj3mk9ceos9e4vgn9tomripq";
  }
};

let cookieDomain = () => {
  // Sets cookie domain depending on environment
  switch (environment) {
    case "prod":
      return ".manulife-wallboard.tcsi2i.com";
    case "dev":
      return ".d31lq3qgvab8wz.cloudfront.net";
    default:
      return "localhost";
  }
};

export const AmplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "us-east-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: userPoolWebClientId(),

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is st to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: cookieDomain(),
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: "lax", //"strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },
    // OPTIONAL - Hosted UI configuration
    oauth: {
      // BASE DOMAIN
      domain: CognitoDomain, //No incluir "https://" pues la librería lo incluye por sí misma.
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      // --- REDIRECT URLS ---
      redirectSignIn: redirectUrl(),
      redirectSignOut: redirectUrl(),
      responseType: "code", // code or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  API: {
    endpoints: [
      {
        name: "wallboard",
        endpoint: api_endpoint() + stage(),
      },
    ],
  },
};

export { environment, api_url };
